import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';
import { RoundedButton, gray2, gray7 } from '@lumoslabs/react_lux';
import ReactHtmlParser from 'react-html-parser';

import Metatags from 'components/Metatags';
import Layout from 'components/layout';
import SubNav from 'components/sub-nav';
import i18n from 'utils/i18n';
import team from './images/team.jpg';

const helpUrl = 'https://www.lumosity.com/zendesk/authentication?return_to=https://help.lumosity.com/hc/';
const helpFormUrl = `${helpUrl}requests/new`;

const styles = StyleSheet.create({
  image: {
    float: 'right',
    height: 286,
    width: 456,
    '@media (max-width: 992px)': {
      width: 342,
      height: 215
    },
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
      verticalAlign: 'middle'
    }
  },
  header: {
    fontSize: '1.9em',
    width: '100%',
    lineHeight: '1.37em'
  },
  copy: {
    fontSize: 15,
    lineHeight: '30px'
  },
  section: {
    marginTop: 20,
    ':last-child': {
      marginBottom: 60
    }
  },
  buttons: {
    marginTop: 40,
    marginBottom: 40
  },
  button: {
    backgroundColor: gray2,
    marginRight: 10,
    color: gray7,
    ':hover': {
      backgroundColor: gray2
    }
  }
});

export default function ContactIndex(props) {
  const {
    location,
    pageContext: { locale, pagePath }
  } = props;
  return (
    <Layout location={location} locale={locale}>
      <Metatags
        title={i18n.t('ContactUsPage.title')}
        description={i18n.t('ContactUsPage.description')}
        type="article"
        canonical={pagePath}
        locale={locale}
      />
      <SubNav title={i18n.t('contact.subNav.title')} />
      <Container>
        <Row className={css(styles.section)}>
          <Col md={6}>
            <div className={css(styles.header)}>{i18n.t('contact.support.header')}</div>
            <div className={css(styles.copy)}>
              {ReactHtmlParser(i18n.t('contact.support.copy', { helpFormUrl, helpUrl }))}
            </div>
            <div className={css(styles.buttons)}>
              <RoundedButton
                styles={[styles.button]}
                value={i18n.t('contact.support.buttons.email')}
                href={helpFormUrl}
              />
              <RoundedButton styles={[styles.button]} value={i18n.t('contact.support.buttons.faq')} href={helpUrl} />
            </div>
          </Col>
          <Col md={6}>
            <img src={team} alt="" className={css(styles.image)} />
          </Col>
        </Row>
        <Row className={css(styles.section)}>
          <Col>
            <div className={css(styles.header)}>{i18n.t('contact.research.header')}</div>
            <div className={css(styles.copy)}>{ReactHtmlParser(i18n.t('contact.research.copy'))}</div>
          </Col>
        </Row>
        <Row className={css(styles.section)}>
          <Col>
            <div className={css(styles.header)}>{i18n.t('contact.press.header')}</div>
            <div className={css(styles.copy)}>
              {ReactHtmlParser(i18n.t('contact.press.copy', { resourcesUrl: '/press' }))}
            </div>
          </Col>
        </Row>
        <Row className={css(styles.section)}>
          <Col>
            <div className={css(styles.header)}>{i18n.t('contact.partnerships.header')}</div>
            <div className={css(styles.copy)}>{ReactHtmlParser(i18n.t('contact.partnerships.copy'))}</div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

ContactIndex.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    pagePath: PropTypes.string.isRequired
  })
};

ContactIndex.defaultProps = {
  pageContext: {
    locale: 'en'
  }
};
